import { useEffect, useRef, useState } from "react"
import type { FC } from "react"

import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import useTheme from "@mui/material/styles/useTheme"
import fp from "lodash/fp"
import { useTranslation } from "react-i18next"
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

import { tickFormatter } from "helpers/formatters/plots/lineChart"
import { useChartZoomXY } from "helpers/hooks/useChartZoomXY"
import { useLineChartsSettings } from "helpers/hooks/useLineChartsSettings"
import { limitsOfReferenceArea } from "helpers/utils/plots"

import type { IDateRange } from "types/date.types"
import type { IData, IDomain, ILineName } from "types/plot.types"

import "./LineChartComponent.styles.css"
import CustomCursorHover from "./components/CustomCursorHover"
import CustomDot from "./components/CustomDot"
import { CustomTooltip } from "./components/CustomTooltip"

interface LineChartsProps {
  data: IData[]
  dateRange: IDateRange
  lineNames: ILineName[]
  setDomain: (domain: IDomain) => void
  XAxisName: string
  background?: Record<string, string>[]
  customDot?: any
  padding?: Record<string, number>
  setHoveredPoint?: (value: any) => void
  title?: string
  valueTooltipFormat?: string
  zoomWithRefetch?: boolean
}

const VerticalBarChart: FC<LineChartsProps> = ({
  data,
  dateRange,
  lineNames,
  setDomain,
  XAxisName,
  background,
  customDot,
  padding,
  setHoveredPoint,
  title,
  valueTooltipFormat,
  zoomWithRefetch = false,
}) => {
  const offset = useRef<Record<string, number> | undefined>()
  const chartSize = useRef<Record<string, number> | undefined>()
  const [axisPadding, setAxisPadding] = useState<Record<string, number> | undefined>({
    top: 15,
    bottom: 15,
  })
  const domainX = useRef<number[] | undefined>()
  const domainY = useRef<number[] | undefined>()
  const theme = useTheme()
  const { t, i18n } = useTranslation()

  const {
    isBeingZoomed,
    state,
    zoomArea,
    zoomInAxisX,
    zoom,
    zoomOut,
    onMouseDown,
    onMouseMove,
  } = useChartZoomXY({
    initialData: data,
    dateRange,
    domainX: domainX.current,
    domainY: domainY.current,
    axisPadding,
    setDomain,
    offset: offset.current,
    zoomWithRefetch,
  })

  const { range, ticksXAxis, ticksYAxis } = useLineChartsSettings({
    data,
    domainY: domainY?.current,
    state,
    XAxisName,
  })

  useEffect(() => {
    if (background) {
      // there is an issue between the background and the padding in the library,
      // the padding is not taken into account when the plot draws the backgroud
      setAxisPadding(undefined)
    } else {
      if (padding) {
        setAxisPadding(padding)
      }
    }
  }, [background, padding])

  return (
    <Stack height="100%" width="100%" pb={3} pr={2}>
      <Stack direction="row" gap={1} pl={4} alignItems="center">
        {title && (
          <Typography variant="h5" p={2}>
            {t(`${title}.TITLE`)}
          </Typography>
        )}
      </Stack>
      <div style={{ width: "100%", height: "100%" }} onDoubleClick={zoomOut}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={state.data}
            margin={{
              top: 10,
              right: 30,
              left: 20,
              bottom: 0,
            }}
            onMouseDown={onMouseDown}
            onMouseUp={zoom}
            onMouseMove={isBeingZoomed ? onMouseMove : undefined}
            /* eslint-disable react/prop-types */
            ref={(props: any) => {
              const height = props?.state.yAxisMap["0"].height // "0" represents the first axis of an array of Y axes
              const width = props?.state.xAxisMap["0"].width // "0" represents the first axis of an array of X axes
              chartSize.current = { height, width }

              if (props && !fp.isEqual(offset.current, props.state.offset)) {
                offset.current = props.state.offset
              }
              if (props && !fp.isEqual(props.state.yAxisMap[0].domain, domainY.current)) {
                domainY.current = props.state.yAxisMap[0].domain
              }
              if (props && !fp.isEqual(props.state.xAxisMap[0].domain, domainX.current)) {
                domainX.current = props.state.xAxisMap[0].domain
              }
            }}
            /* eslint-enable react/prop-types */
          >
            <CartesianGrid
              strokeDasharray="3 3"
              stroke={theme.palette.grey[400]}
              strokeWidth={0.5}
            />
            <XAxis
              dataKey={XAxisName}
              scale="time"
              stroke={theme.palette.text.primary}
              allowDuplicatedCategory={false}
              allowDataOverflow
              ticks={ticksXAxis}
              domain={zoomWithRefetch ? ["auto", "auto"] : [state.left, state.right]}
              type="number"
              tickFormatter={(unixTimestamp) =>
                tickFormatter(unixTimestamp, range, i18n.language)
              }
            />
            <YAxis
              // domain={["auto", "auto"]}
              ticks={ticksYAxis}
              tickFormatter={(value) => `${value.toFixed(2)}`}
              domain={[state.top, state.bottom]}
              stroke={theme.palette.text.primary}
              allowDataOverflow
              type="number"
              padding={axisPadding}
            />
            <Tooltip
              cursor={
                !isBeingZoomed && (
                  <CustomCursorHover
                    XAxisName={XAxisName}
                    axisPadding={axisPadding}
                    domainX={domainX}
                    domainY={domainY}
                  />
                )
              }
              content={
                !isBeingZoomed ? (
                  <CustomTooltip
                    domainX={domainX}
                    domainY={domainY}
                    axisPadding={axisPadding}
                    valueTooltipFormat={valueTooltipFormat}
                    XAxisName={XAxisName}
                    setHoveredPoint={setHoveredPoint}
                  />
                ) : (
                  <></>
                )
              }
            />
            {background?.map((section, index) => {
              const [top, bottom] = limitsOfReferenceArea(section, state)

              return (
                <ReferenceArea
                  key={index}
                  y2={top}
                  y1={bottom}
                  fill={section.color}
                  opacity={0.45}
                  strokeOpacity={0.3}
                  isFront={false}
                />
              )
            })}
            {data.flatMap((s, sindex) => {
              return lineNames.map((line, index) => {
                return (
                  <Line
                    data={s.data}
                    name={line.key}
                    key={`${sindex}_${index}`}
                    dataKey={line.key}
                    type={line?.type ?? "monotone"}
                    strokeWidth={1.5}
                    activeDot={
                      <CustomDot
                        activeDot={true}
                        domainX={domainX}
                        domainY={domainY}
                        fill={line.stroke}
                        XAxisName={XAxisName}
                        axisPadding={axisPadding}
                        chartSize={chartSize}
                        radio={!isBeingZoomed ? 4 : undefined}
                        stroke={!isBeingZoomed ? "#ffffff" : ""}
                      />
                    }
                    fill={line.stroke}
                    stroke={line.stroke}
                    dot={customDot ? customDot : <CustomDot fill={line.stroke} />}
                    animationDuration={300}
                  />
                )
              })
            })}
            {zoomInAxisX ? (
              <ReferenceArea x1={zoomArea.x1} x2={zoomArea.x2} strokeOpacity={0.3} />
            ) : (
              <ReferenceArea y1={zoomArea.y1} y2={zoomArea.y2} strokeOpacity={0.3} />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </Stack>
  )
}

export default VerticalBarChart
