// /* eslint-disable react/prop-types */
// import { FC, useCallback, useState, useEffect } from "react"
// import { useTranslation } from "react-i18next"
// import { Stack } from "@mui/material"
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   ResponsiveContainer,
//   Tooltip,
//   // Cell,
// } from "recharts"
// import { tickFormatter } from "../../../helpers/formatters/plots"
// import { arrayRange } from "../../../helpers/formatters/plots"
// import { CustomBarChartTooltip } from "./Components/CustomBarChartTooltip"

// /**
//  * Example of how the chart should receive the data
//  */
// const data = [
//   {
//     name: "moves",
//     transp_moves_0: 14003000,
//     high_moves_0: 200000,
//     medium_moves_0: 35743400,
//     transp_moves_1: 140030000,
//     medium_moves_1: 5444100,
//     transp_moves_2: 140030000,
//     medium_moves_2: 2520000,
//     high_moves_1: 7711000,
//     transp_moves_3: 140030000,
//     medium_moves_3: 187140000,
//   },
//   {
//     name: "wind",
//     transp_wind_0: 14030000,
//     high_wind_0: 14000000,
//     transp_wind_1: 140030000,
//     medium_wind_1: 140030000,
//     transp_wind_2: 140030000,
//     high_wind_1: 10100000,
//   },
//   {
//     name: "break",
//     transp_break_0: 140030000,
//     high_break_0: 971100,
//   },
// ]

// const colors = {
//   transp: "rgba(0,0,0,0)",
//   low: "rgba(65, 179, 143, 0.4)",
//   medium: "rgba(249, 176, 51, 0.4)",
//   high: "rgba(255, 81, 81, 0.4)",
// }

// type SeverityType = "transp" | "low" | "medium" | "high"

// interface VerticalBarChartProps {
//   data: Record<string, string | number>[]
//   keys: string[]
// }

// export const VerticalBarChart: FC<VerticalBarChartProps> = () =>
//   // { data, keys }
//   {
//     const { t, i18n } = useTranslation()
//     // const [dataFormatted, setDataFormatted] = useState<any[]>()
//     const [hoveredElement, setHoveredElement] = useState()
//     const [activeElementIndex, setActiveElementIndex] = useState()

//     const barsGenerator = () => {
//       const bars = data?.map((data: any, index: number): any => {
//         return Object.entries(data).map(([key, value]) => {
//           if (key !== "name") {
//             const severity = key.split("_")
//             return (
//               <Bar
//                 key={`${index}${key}`}
//                 fill={colors[severity[0] as SeverityType]}
//                 stackId={"data.name"}
//                 dataKey={key}
//                 animationDuration={300}
//                 onClick={handleClick}
//                 onMouseOver={handleOnChange}
//                 onMouseLeave={() => setHoveredElement(undefined)}
//                 stroke={
//                   hoveredElement && key === activeElementIndex ? "#ff0000" : undefined
//                 }
//               />
//             )
//           }
//         })
//       })

//       return bars?.flat()
//     }

//     // useEffect(() => {
//     //   if (data && keys) {
//     //     const group = groupByKeyBarPlot(data, keys)
//     //     setDataFormatted(group)
//     //   }
//     // }, [data, keys])

//     const handleClick = useCallback((entry: any, index: number) => {
//       console.log(entry)
//     }, [])

//     const handleOnChange = (entry: any, index: number) => {
//       console.log(entry.tooltipPayload)

//       setHoveredElement(entry.tooltipPayload)

//       setActiveElementIndex(entry.tooltipPayload[0].dataKey)
//     }

//     useEffect(() => {
//       console.log(hoveredElement, activeElementIndex)
//     }, [hoveredElement, activeElementIndex])

//     return data ? (
//       <Stack height="100%" width="100%" pb={3} pr={2}>
//         <ResponsiveContainer width="100%" height="100%">
//           <BarChart
//             height={300}
//             data={data}
//             margin={{
//               top: 5,
//               right: 30,
//               left: 20,
//               bottom: 5,
//             }}
//             layout="vertical"
//           >
//             <CartesianGrid
//               strokeDasharray="3 3"
//               horizontalCoordinatesGenerator={(props) => {
//                 const qtyOfBars = data.length

//                 return arrayRange(
//                   props.offset.top,
//                   props.offset.height,
//                   props.offset.height / qtyOfBars,
//                 )
//               }}
//             />
//             <Tooltip
//               content={
//                 <CustomBarChartTooltip
//                   hoveredBar={hoveredElement}
//                   offset={1681071101800}
//                 />
//               }
//             />
//             <XAxis
//               type="number"
//               domain={["auto", "auto"]}
//               tickFormatter={(unixTimestamp) =>
//                 //The range is hardcoded, also the gap
//                 // 1681071101800 is the first date of the time serie
//                 tickFormatter(unixTimestamp + 1681071101800, 885348878, i18n.language)
//               }
//               allowDataOverflow
//             />
//             <YAxis
//               type="category"
//               dataKey="name"
//               tickFormatter={(label) =>
//                 t(`fast_incident_detection_plot.${label.toUpperCase()}`)
//               }
//             />
//             {barsGenerator()}
//           </BarChart>
//         </ResponsiveContainer>
//       </Stack>
//     ) : (
//       <></>
//     )
//   }

//
//
//
//
//

/* eslint-disable react/prop-types */
import type { FC } from "react"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import type { Theme } from "@mui/material"
import { Stack, Typography } from "@mui/material"
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  // Cell,
} from "recharts"
import { useTheme } from "@mui/system"

import { getBarPlotSeverityColor } from "helpers/utils/styles"
import { arrayRange } from "helpers/utils/common"
import { tickFormatter } from "helpers/formatters/plots/lineChart"

import { CustomBarChartTooltip } from "./components/CustomBarChartTooltip"
// import { CustomBarChartTooltip } from "./Components/CustomBarChartTooltip"

/**
 * !important
 *
 * generating a X and Y axis per each bar (an then hide them) is the only way that i could find
 * to have *stacked range bars*
 *
 * here i found the same solution: https://github.com/recharts/recharts/issues/1510
 *
 * stack and range functions don't work fine together, especially in *vertical layout*
 */

interface VerticalBarChartProps {
  data: Record<string, string | number>[]
  title: string
  barGap?: number
  hideHorizontalCartesianLines?: boolean
  hideTick?: boolean
}

export const VerticalBarChart: FC<VerticalBarChartProps> = ({
  data,
  title,
  barGap,
  hideHorizontalCartesianLines = false,
  hideTick = false,
}) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const [barsComponents, setBarsComponents] = useState<any[]>()
  const [xaxisComponents, setXaxisComponents] = useState<any[]>()
  const [yaxisComponents, setYaxisComponents] = useState<any[]>()
  const [hoveredElement, setHoveredElement] = useState()
  const [activeElementIndex, setActiveElementIndex] = useState()

  const barsGenerator = useCallback(() => {
    const bars: any[] = []
    const yaxis: any[] = []
    const xaxis: any[] = []

    data?.forEach((data: any, index: number): any => {
      Object.entries(data).forEach(([key, _value]) => {
        if (key !== "name") {
          const severity = key.split("_")
          bars.push(
            <Bar
              key={`bar${index}${key}`}
              fill={getBarPlotSeverityColor(severity[0], theme as Theme)}
              fillOpacity={hoveredElement && key === activeElementIndex ? 0.5 : 0.4}
              dataKey={key}
              xAxisId={key}
              yAxisId={key}
              animationDuration={300}
              onMouseOver={handleOnChange}
              onMouseLeave={() => setHoveredElement(undefined)}
              stroke={getBarPlotSeverityColor(severity[0], theme as Theme)}
            />,
          )

          // This is a nasty way to solve it.
          // But is the only working way to pass the values in the range and stack the bars :/
          yaxis.push(
            <YAxis
              key={`y${index}${key}`}
              hide
              yAxisId={key}
              type="category"
              dataKey="name"
            />,
          )
          xaxis.push(
            <XAxis
              key={`x${index}${key}`}
              type="number"
              hide
              xAxisId={key}
              interval="preserveStartEnd"
              domain={[1680001400000, 1680981500000]}
              allowDataOverflow
            />,
          )
        }
      })
    })
    return [bars, xaxis, yaxis]
  }, [activeElementIndex, theme, data, hoveredElement])

  useEffect(() => {
    const [bars, xaxis, yaxis] = barsGenerator()

    setXaxisComponents(xaxis)
    setYaxisComponents(yaxis)
    setBarsComponents(bars)
  }, [barsGenerator])

  const handleOnChange = (entry: any, _index: number) => {
    setHoveredElement(entry.tooltipPayload)
    setActiveElementIndex(entry.tooltipPayload[0].dataKey)
  }

  return data ? (
    <Stack height="100%" width="100%" pb={3} pr={2}>
      <Typography variant="h5" p={2}>
        {t(`${title}.TITLE`)}
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          layout="vertical"
          barCategoryGap={barGap}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            horizontal={!hideHorizontalCartesianLines}
            stroke={theme.palette.grey[400]}
            strokeWidth={0.5}
            horizontalCoordinatesGenerator={(props) => {
              const qtyOfBars = data.length
              return arrayRange(
                props.offset.top ?? 0,
                props.offset.height ?? 0,
                (props.offset.height ?? 0) / qtyOfBars,
              )
            }}
          />
          <Tooltip
            cursor={{ fill: "transparent" }}
            content={<CustomBarChartTooltip hoveredBar={hoveredElement} title={title} />}
          />
          <YAxis
            key={"bar-1"}
            type="category"
            dataKey="name"
            tick={!hideTick}
            stroke={theme.palette.text.primary}
            tickFormatter={(label) => t(`${title}.${label.toUpperCase()}`)}
          />
          <XAxis
            type="number"
            stroke={theme.palette.text.primary}
            interval="preserveStartEnd"
            domain={[1680001400000, 1680981500000]}
            tickFormatter={(unixTimestamp) =>
              tickFormatter(unixTimestamp, 885348878, i18n.language)
            }
            allowDataOverflow
          />
          {yaxisComponents}
          {xaxisComponents}
          {barsComponents}
        </BarChart>
      </ResponsiveContainer>
    </Stack>
  ) : (
    <></>
  )
}
