export const ArrowDot = (props: any) => {
  const { cx, cy, value, payload } = props

  if (value > 2500) {
    return (
      <svg
        x={cx - 12}
        y={cy - 25}
        width="160"
        height="160"
        fill="red"
        viewBox="0 0 100 100"
      >
        <path
          style={{
            transform: ` rotate(${payload.ang}deg)`,
            transformBox: "fill-box",
            transformOrigin: "bottom center",
          }}
          fillRule="evenodd"
          d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
        />
      </svg>
    )
  }

  return (
    <svg
      x={cx - 12}
      y={cy - 25}
      width="160"
      height="160"
      fill="green"
      viewBox="0 0 100 100"
    >
      <path
        style={{
          transform: `rotate(${payload.ang}deg)`,
          transformBox: "fill-box",
          transformOrigin: "bottom center",
        }}
        fillRule="evenodd"
        d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
      />
    </svg>
  )
}
