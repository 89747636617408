import type { FC } from "react"
import { useEffect, useState } from "react"
import { polarToCartesian } from "../../../../helpers/utils/common"
import type { IPosition, IRadius, IRange } from "../../../../types/gaugePlot.types"

interface FinalDotProps {
  radius: IRadius
  position: IPosition
  gaugeAngle: number | null
  range: IRange
}
const FinalDot: FC<FinalDotProps> = ({ gaugeAngle, radius, position, range }) => {
  const meanRadius = (radius.outer + radius.inner) / 2
  const dotRadius = radius.delta / 9
  const [dX, setDX] = useState(0)
  const [dY, setDY] = useState(0)

  useEffect(() => {
    let fPointAngle = 0

    if (gaugeAngle !== null) {
      const dRange = Math.abs(range.max - range.min)
      const translationAngle = (dRange - 180) / 2

      // with this calculation we translate the axis to obtain an acute angle,
      // and then calculate dot position
      if (gaugeAngle <= 90) {
        fPointAngle = gaugeAngle + (180 - translationAngle)
      } else if (gaugeAngle > 90) {
        fPointAngle = gaugeAngle - 180 - translationAngle
      }
    }

    const [positionX, positionY] = polarToCartesian(meanRadius, fPointAngle)

    setDX(position.cx - positionX - dotRadius)
    setDY(position.cy + positionY - dotRadius)
  }, [dotRadius, range, gaugeAngle, meanRadius, position])

  if (gaugeAngle !== 0 && !gaugeAngle) {
    return <></>
  }

  return (
    <g transform={`translate(${dX},${dY})`}>
      <svg height={dotRadius * 2} width={dotRadius * 2}>
        <circle cx={dotRadius} cy={dotRadius} r={dotRadius} fill="#ffffff" />
      </svg>
    </g>
  )
}

export default FinalDot
