import type { IGaugeData, IGaugeDomain, IRange } from "types/gaugePlot.types"

interface IGaugeValues {
  valueOnRangeDisplaced: number
  valueOnRangeExact: number
}

interface IGaugeDataChartFormatter {
  cellDataFormatted: IGaugeData[]
  valueOnRangeExact: number
}

/**
 * Convert a value from Domain scale to Range scale
 *
 * @param {number} value
 * @param {IRange} dRange
 * @param {number} dDomain
 * @returns {number} value on the **range scale**. Now its unit is degrees
 */
const fromDomainToRange = (value: number, dRange: number, dDomain: number): number => {
  return (value * dRange) / dDomain
}

/**
 * Convert a value from Range scale to Domain scale
 *
 * @param {number} value
 * @param {IRange} dRange
 * @param {number} dDomain
 * @returns {number} value on the **range scale**
 */
const fromRangeToDomain = (value: number, dRange: number, dDomain: number): number => {
  return (value * dDomain) / dRange
}

/**
 * Calculates the 'rangeOffset'. This is calculated as a ratio of the angle
 * covered for the 'cell' (sweepAngle) and the dRange.
 *
 * @param {number} angle
 * @returns {number} rangeOffset on **Range scale**
 */
const calculateOffsetRange = (angle: number, dRange: number, offset: number): number => {
  const sweepAngle = dRange - angle

  return offset * (sweepAngle / dRange)
}

/**
 * This function calculates the 'exactValue' and 'cellValue' on the **Range scale**.
 * The 'cellValue' is the exact value with the 'offset' displacement.
 * In this way, we can generate the gauge plot with the dot pointing the exact value
 * and the cell displaced, keeping a *constant* offset of the dot.
 *
 * To keep this *offset* constant we calculate an **offsetDomain** and an **offsetRange**.
 */
const calculateDotAndCellValue = (
  rawValue: number,
  domain: IGaugeDomain,
  dRange: number,
  offset: number,
): IGaugeValues => {
  const dDomain = Math.abs(domain.max - domain.min)
  const value =
    rawValue > domain.max ? domain.max : rawValue < domain.min ? domain.min : rawValue
  const exactValue = Math.abs(domain.min - value)
  const offsetDomain = fromRangeToDomain(offset, dRange, dDomain)

  const cellValue = fromDomainToRange(exactValue + offsetDomain, dRange, dDomain)
  const valueOnRangeExact = fromDomainToRange(exactValue, dRange, dDomain)

  const offsetRange = calculateOffsetRange(valueOnRangeExact, dRange, offset)
  const valueOnRangeDisplaced = cellValue + offsetRange

  return { valueOnRangeDisplaced, valueOnRangeExact }
}

/**
 * Format the data received from influxDB to plot a Recharts Gauge Chart
 *
 * @param {number} rawValue is the gauge value on the **domain scale**
 * @param {ILineChartDomain} domain
 * @param {number} offset it is an angle and it represent the offset of the extreme values of the 'cell' from the exact value
 * @param {IRange} range
 * @param {string} fill
 * @param {boolean} hasGradient
 * @returns {IGaugeDataChartFormatter} **cellDataFormatted** formatted to plot the cell, **valueOnRangeExact** angle indicating the exact position
 */
export const gaugeDataChartFormatter = (
  rawValue: number,
  domain: IGaugeDomain,
  offset: number,
  range: IRange,
  fill: string,
  hasGradient: boolean,
): IGaugeDataChartFormatter => {
  const dRange = Math.abs(range.max - range.min)

  const { valueOnRangeDisplaced, valueOnRangeExact } = calculateDotAndCellValue(
    rawValue,
    domain,
    dRange,
    offset,
  )

  const cellDataFormatted = [
    {
      name: "value",
      value: valueOnRangeDisplaced,
      color: hasGradient ? `url(#${fill})` : fill,
    },
    {
      name: "void",
      value: dRange - valueOnRangeDisplaced,
      color: "transparent",
    },
  ]

  return { cellDataFormatted, valueOnRangeExact }
}
