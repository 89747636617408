import type { FC, MutableRefObject } from "react"
import { useEffect, useState } from "react"
import type { DotProps } from "recharts"
import { Dot } from "recharts"
import type {
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent"
import { areCoordsOnDomain } from "../../../../helpers/utils/plots"

interface CustomDotProps {
  fill: string
  activeDot?: boolean
  axisPadding?: Record<string, number>
  chartSize?: MutableRefObject<Record<string, number> | undefined>
  domainX?: MutableRefObject<number[] | undefined>
  domainY?: MutableRefObject<number[] | undefined>
  radio?: number
  stroke?: string
  XAxisName?: string
}

const CustomDot: FC<DotProps & CustomDotProps & Payload<ValueType, NameType>> = ({
  fill,
  activeDot,
  axisPadding,
  chartSize,
  domainX,
  domainY,
  radio = 2,
  stroke,
  XAxisName,
  ...props
}) => {
  const { cx, cy, payload, dataKey } = props
  const [isAValidValue, setIsAValidValue] = useState<boolean>(activeDot ? false : true)

  /**
   * This useEffect checks if the dot coordinates of the payload are in the domain
   */
  useEffect(() => {
    if (activeDot && payload && domainX?.current && domainY?.current) {
      const xCoord = payload[XAxisName ?? ""]
      const yCoord = payload[dataKey as string]
      const viewBox = { height: chartSize?.current?.height }
      const isAValidValue = areCoordsOnDomain(
        xCoord,
        yCoord,
        domainX.current,
        domainY.current,
        axisPadding,
        viewBox,
      )
      setIsAValidValue(isAValidValue)
    }
  }, [activeDot, XAxisName, axisPadding, chartSize, dataKey, domainX, domainY, payload])

  if (!isAValidValue) {
    return <></>
  }

  return <Dot cx={cx} cy={cy} r={radio} fill={fill} stroke={stroke} strokeWidth={1} />
}

export default CustomDot
