import type { FC, MutableRefObject, SVGProps } from "react"
import { useEffect, useMemo, useState } from "react"
import type { TooltipProps } from "recharts"
import { Rectangle } from "recharts"
import type { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent"
import type { Props } from "recharts/types/cartesian/Line"
import { areCoordsOnDomain } from "../../../../helpers/utils/plots"

interface CustomCursorHoverProps {
  domainX: MutableRefObject<number[] | undefined>
  domainY: MutableRefObject<number[] | undefined>
  XAxisName: string
  customWidth?: number
  axisPadding?: Record<string, number>
  fill?: string
}

const CustomCursorHover: FC<
  CustomCursorHoverProps &
    Props &
    TooltipProps<ValueType, NameType> &
    SVGProps<SVGElement>
> = ({
  domainX,
  domainY,
  XAxisName,
  customWidth = 0.5,
  axisPadding,
  fill = "#fff",
  ...props
}) => {
  const { height, points, payload, top } = props
  const [isAnyCoordsOnDomain, setIsAnyCoordsOnDomain] = useState<boolean>(false)
  const viewBox = useMemo(() => {
    return { height }
  }, [height])

  /**
   * This useEffect checks if the dot coordinates of the payload are in the domain
   */
  useEffect(() => {
    if (payload?.length && domainX.current && domainY.current) {
      const xCoord = payload[0].payload[XAxisName ?? ""]
      const validPayloads = payload.some((payload_item) => {
        return (
          typeof payload_item?.value === "number" &&
          domainX.current &&
          domainY.current &&
          areCoordsOnDomain(
            xCoord,
            payload_item?.value,
            domainX.current,
            domainY.current,
            axisPadding,
            viewBox,
          )
        )
      })

      setIsAnyCoordsOnDomain(validPayloads)
    } else {
      setIsAnyCoordsOnDomain(false)
    }
  }, [XAxisName, axisPadding, domainX, domainY, viewBox, payload])

  if (!points || points[0].length <= 0) {
    return <></>
  }

  if (!isAnyCoordsOnDomain) {
    return <></>
  }

  return (
    <Rectangle
      x={points[0]?.x - customWidth / 2}
      y={0 + (top ?? 0)}
      fill={fill}
      width={customWidth}
      height={height}
    />
  )
}

export default CustomCursorHover
