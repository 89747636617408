import { useMemo } from "react"
import { useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"

import type { RecordType, CellViewType, RecordService } from "types/dashboard.types"
import type { IPieConfig } from "types/piePlot.types"

import { getRecordService } from "helpers/formatters/dataFormatters"
import { pieConfig } from "./pie"

export default function usePieConfig(view: CellViewType, data: RecordType[]) {
  const { palette } = useTheme()
  const { t } = useTranslation()

  const recordService: RecordService = useMemo(() => {
    const fill = ["_field"]
    return getRecordService(fill, t)
  }, [t])

  const graphConfig: IPieConfig = useMemo(
    () => pieConfig({ view, data, palette, t, recordService }),
    [view, data, palette, t, recordService],
  )

  return graphConfig
}
