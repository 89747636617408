import { type Palette } from "@mui/material"
import fp from "lodash/fp"
import type { TFunction } from "react-i18next"

import type {
  CellViewType,
  ColorType,
  RecordService,
  RecordType,
} from "types/dashboard.types"
import type { IPieConfig } from "types/piePlot.types"

import { getSequentialColor } from "helpers/utils/styles"

interface PieConfigParams {
  view: CellViewType
  data: RecordType[]
  palette: Palette
  recordService: RecordService
  t: TFunction
}

export function pieConfig({
  view,
  data,
  palette,
  recordService,
  t,
}: PieConfigParams): IPieConfig {
  const colors = view.properties.colors as ColorType[]
  const colorsByValue = fp.indexBy("value", colors)

  const plotData = data.map((d, index) => ({
    name: d.nameKey ? t(d.nameKey) : recordService.getName(d),
    value: d.value ? Number(d.value) : Number(d._value),
    color: d.colorKey
      ? String(colorsByValue[d.colorKey as keyof typeof colorsByValue]?.hex)
      : getSequentialColor(index),
  }))

  const filteredPlotData = plotData.filter((d) => d.value)

  const config = {
    filter: palette.glow.main,
    plotSize: { length: 250, lengthMobile: 180 },
    plotData,
    filteredPlotData,
  }

  return config
}
