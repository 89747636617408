import Circle from "@mui/icons-material/Circle"
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useMemo } from "react"
import { PieChart as RechartsPieChart, Pie, Cell, LabelList } from "recharts"
import type { CellViewRes, RecordType } from "types/dashboard.types"
import type { IPieData } from "types/piePlot.types"
import usePieConfig from "widgets/plots/configuration/usePieConfig"

interface PieChartProps {
  view: CellViewRes
  data: RecordType[]
}

const PieChart = ({ view, data }: PieChartProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const config = usePieConfig(view, data)
  const { filter, plotSize, plotData, filteredPlotData } = config

  // resize pie when viewport width is 'sm'
  const size = useMemo(
    () => (isMobile ? plotSize.lengthMobile : plotSize.length),
    [isMobile, plotSize],
  )
  const radius = {
    delta: size / 13,
    inner: size / 4.5,
    outer: size / 2.75 + size / 13,
  }
  const position = { cx: size / 2, cy: size / 2 }
  const label = theme.typography.body1.fontSize
  const circleIcon = size / 28

  return (
    <Stack direction={"row"} justifyContent="center" alignItems="center" gap={2}>
      <RechartsPieChart
        width={size}
        height={size}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <Pie
          isAnimationActive={false}
          dataKey="value"
          data={filteredPlotData}
          cx={position.cx}
          cy={position.cy}
          innerRadius={radius.inner}
          outerRadius={radius.outer}
          stroke="none"
          style={{
            outline: "none",
            filter: `drop-shadow(0px 0px 5px ${filter}`,
          }}
        />
        <Pie
          isAnimationActive={false}
          dataKey="value"
          data={filteredPlotData}
          cx={position.cx}
          cy={position.cy}
          innerRadius={radius.inner}
          outerRadius={radius.outer}
          stroke={theme.palette.background.default}
        >
          <LabelList
            dataKey="value"
            style={{ fontSize: label, fontWeight: "bold", fill: theme.palette.grey[800] }}
            stroke="none"
          />
          {filteredPlotData.map((entry: IPieData, index: number) => (
            <Cell
              key={`cell-${index}`}
              fill={entry.color}
              style={{
                outline: "none",
              }}
            />
          ))}
        </Pie>
      </RechartsPieChart>
      <Stack gap={1} width={120}>
        {plotData.map((pd) => {
          return (
            <Stack key={pd.name} direction={"row"} alignItems="center" gap={1}>
              <Circle sx={{ color: pd.color, fontSize: circleIcon }} />
              <Typography>{pd.name}</Typography>
            </Stack>
          )
        })}
      </Stack>
    </Stack>
  )
}

export default PieChart
