import type { Palette } from "@mui/material"
import { DateTime } from "luxon"

import { getLegend } from "helpers/utils/plots"
import type { IGaugeConfig } from "types/gaugePlot.types"
import type {
  CellViewType,
  ColorType,
  CustomProperties,
  RecordType,
} from "types/dashboard.types"
import type { ILegendRecord } from "helpers/utils/plots"

interface GaugeConfigParams {
  view: CellViewType
  data: RecordType[]
  palette: Palette
}

export function gaugeConfig({ view, data, palette }: GaugeConfigParams): IGaugeConfig {
  const name = view.name.toLowerCase().replace(/\s/g, "")

  /**
   * The radialGradients parameters were taken from Figma's design
   */
  const colorGradient = {
    dangerToGood: [
      { offset: 0.25, color: palette.error.main },
      { offset: 0.71, color: palette.warning.main },
      { offset: 0.9, color: palette.secondary.main },
    ],
    default: [
      { offset: 0.25, color: palette.error.main },
      { offset: 0.71, color: palette.warning.main },
      { offset: 0.9, color: palette.secondary.main },
    ],
  }

  const gradient =
    name.includes("battery") || name.includes("signal") ? "dangerToGood" : "default"
  const fill = {
    name,
    gradient: {
      name,
      colorGradient: colorGradient[gradient],
      scale: [360, 360],
      rotate: -45,
      cx: 0,
      cy: 0,
      r: 1,
      translate: [-30, 300],
    },
  }

  const getDomain = (colors: ColorType[]) => {
    const colorValues = colors?.map((color) => color.value)
    return {
      min: Math.min(...colorValues),
      max: Math.max(...colorValues),
    }
  }

  const formatValue = (value?: string | number | string[]): number => {
    if (typeof value === "string") {
      const valueFormatted = Number.parseFloat(value)
      return !Number.isNaN(valueFormatted) ? valueFormatted : 0
    }
    if (typeof value === "number") {
      return value
    }
    if (Array.isArray(value)) {
      const valueFormatted = Number.parseFloat(value[value.length - 1])
      return !Number.isNaN(valueFormatted) ? valueFormatted : 0
    }
    return 0 // in this case it's undefined
  }

  const getPlotFooter = (data: RecordType[]) => {
    return (view.properties as CustomProperties)?.footerFields?.includes("_time")
      ? DateTime.fromISO(data[0]._time as string).toFormat("yyyy-MM-dd HH:mm:ss")
      : undefined
  }

  const config = {
    cellBG: {
      fill: palette.gaugeBG.main,
      filter: palette.grey[500],
    },
    decimalPlaces: view.properties.decimalPlaces?.digits || 0,
    fill,
    domain: getDomain(view.properties.colors as ColorType[]),
    plotSize: { length: 340, lengthMobile: 300 },
    range: { min: -30, max: 210 },
    ticks: {
      valueInterval: (view.properties as CustomProperties).ticksValueInterval || 4,
      fill: palette.text.primary,
      prefix: view.properties.tickPrefix || "",
      suffix: view.properties.tickSuffix || "",
    },
    values: {
      footer: getPlotFooter(data),
      legend: getLegend(data as ILegendRecord[]),
      value: formatValue(data[0]._value),
      fill: palette.text.primary,
      prefix: view.properties.prefix ?? "",
      suffix: view.properties.suffix ?? "",
    },
  }

  return config
}
