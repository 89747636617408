import { useEffect, useState } from "react"

import { ticksGenerator, ticksYAxisGenerator } from "helpers/formatters/plots/lineChart"

import type { IData } from "types/plot.types"

interface useLineChartsSettingsProps {
  data: IData[]
  state: any
  XAxisName: string
  domainY?: number[]
}

export const useLineChartsSettings = ({
  data,
  state,
  XAxisName,
  domainY,
}: useLineChartsSettingsProps) => {
  const [range, setRange] = useState<number>(0)
  const [ticksXAxis, setTicksXAxis] = useState<number[]>()
  const [ticksYAxis, setTicksYAxis] = useState<number[]>()
  const [limits, setLimits] = useState({
    left: null,
    right: null,
  })

  /**
   * This useEffect extracts the first and last time value in a time series
   */
  useEffect(() => {
    let left
    let right
    if (typeof state.left === "number" && typeof state.right === "number") {
      left = state.left
      right = state.right
    } else if (data) {
      // TODO: this assumes that the data is sorted by XAxisName, which basically depends on the behavior of the query
      left = data[0].data[0][XAxisName]
      right = data.at(-1)?.data.at(-1)[XAxisName]
    }
    setLimits({
      left,
      right,
    })
    setRange(right - left)
  }, [XAxisName, state.left, state.right, data])

  /**
   * This useEffect generates the tick array for X axis with the limits data
   */
  useEffect(() => {
    if (limits.left && limits.right) {
      const ticks = ticksGenerator(limits)
      setTicksXAxis(ticks)
    }
  }, [limits])

  /**
   * This useEffect generates the tick array for Y axis with the limits data
   */
  useEffect(() => {
    const limits = { top: 0, bottom: 0 }
    if (typeof state.top === "number" && typeof state.bottom === "number") {
      limits.top = state.top
      limits.bottom = state.bottom
    } else if (domainY) {
      limits.top = domainY[0]
      limits.bottom = domainY[1]
    }

    const ticks = ticksYAxisGenerator(limits)
    setTicksYAxis(ticks)
  }, [state.top, state.bottom, domainY])

  return {
    limits,
    range,
    ticksXAxis,
    ticksYAxis,
  }
}
