import type { FC } from "react"

import type { IFillGradient } from "types/gaugePlot.types"
import type { IColorGradient } from "types/plot.types"

interface Props {
  config: IFillGradient
}

const RadialGradientComponent: FC<Props> = ({ config }) => {
  const { name, colorGradient, cx, cy, r, scale, translate, rotate } = config

  return (
    <radialGradient
      id={name}
      cx={`${cx}`}
      cy={`${cy}`}
      r={`${r}`}
      gradientUnits="userSpaceOnUse"
      gradientTransform={`translate(${translate[0]} ${translate[1]}) rotate(${rotate}) scale(${scale[0]} ${scale[1]})`}
    >
      {colorGradient.map((color: IColorGradient, index: number) => {
        return <stop key={index} offset={color.offset} stopColor={color.color} />
      })}
    </radialGradient>
  )
}

export default RadialGradientComponent
