import type { FC } from "react"
import { polarToCartesian } from "../../../../helpers/utils/common"
import type { ITicksProps } from "../../../../types/gaugePlot.types"

interface GaugeTicksProps {
  gaugeProps: ITicksProps
  ticks: any
}

const GaugeTicks: FC<GaugeTicksProps> = ({ gaugeProps, ticks }) => {
  const { x, y, payload } = gaugeProps
  const { valueInterval, fill, prefix, suffix } = ticks
  const distDotText = 15 // in px
  const [positionX, positionY] = polarToCartesian(distDotText, payload.coordinate)

  const dX = positionX
  const dY = positionY

  return (
    <>
      {gaugeProps.index % valueInterval === 0 && (
        <g transform={`translate(${x - dX},${y - dY})`}>
          <text textAnchor="middle" fill={`${fill}`}>
            {prefix} {payload.value} {suffix}
          </text>
        </g>
      )}

      <g transform={`translate(${x},${y})`}>
        <svg height="2" width="2">
          <circle cx={"1"} cy={"1"} r="0.5" fill={`${fill}`} />
        </svg>
      </g>
    </>
  )
}

export default GaugeTicks
