import { Stack, Typography } from "@mui/material"
import type { FC, MutableRefObject } from "react"
import { useEffect, useRef, useState } from "react"
import { DateTime } from "luxon"
import type {
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent"
import type { TooltipProps } from "recharts"
import type { Props } from "recharts/types/cartesian/Line"
import { degreesToString } from "../../../../helpers/utils/common"
import { areCoordsOnDomain } from "../../../../helpers/utils/plots"

interface CustomTooltipProps {
  domainX: MutableRefObject<number[] | undefined>
  domainY: MutableRefObject<number[] | undefined>
  axisPadding?: Record<string, number>
  valueTooltipFormat?: string
  XAxisName: string
  setHoveredPoint?: (value?: Payload<ValueType, NameType>[]) => void
}

export const CustomTooltip: FC<
  CustomTooltipProps & Props & TooltipProps<ValueType, NameType>
> = ({
  axisPadding,
  domainX,
  domainY,
  XAxisName,
  setHoveredPoint,
  valueTooltipFormat,
  ...props
}) => {
  const hoveredPoint = useRef<Payload<ValueType, NameType>[] | undefined>() // this useRef reduces the number of unnecessary updates to the 'hoveredPoint' state
  const { active, payload, viewBox, label } = props
  const [payloadsOnDomainScale, setPayloadsOnDomainScale] = useState<
    Payload<ValueType, NameType>[] | undefined
  >()

  const valueFormatter = (value: string | number) => {
    if (valueTooltipFormat && valueTooltipFormat === "degree") {
      return degreesToString(Number(value))
    }
    if (typeof value === "number") {
      return value.toFixed(2)
    } else {
      return value
    }
  }

  /**
   * This useEffect manages the logic to set when a point is hovered or not,
   * if a function to set a value was received as prop.
   */
  useEffect(() => {
    if (setHoveredPoint) {
      // If the dot is being hovered (and it has a payload) set the 'hoveredPoint' state, at least this value is alredy setted
      if (active) {
        if (
          payload &&
          payload.length &&
          (!hoveredPoint.current ||
            (hoveredPoint.current &&
              hoveredPoint.current[0]?.value !== payload[0]?.value))
        ) {
          hoveredPoint.current = payload
          setHoveredPoint(payload)
        }
      }

      // If no dot is being hovered, set state as undefined, at least this state is alredy undifined
      if (!active) {
        if (hoveredPoint.current) {
          hoveredPoint.current = undefined
          setHoveredPoint(undefined)
        }
      }
    }
  }, [active, domainX, payload, setHoveredPoint])

  /**
   * This useEffect manages the logic to generate an array of payloads with values
   * within the domains of the chart.
   */
  useEffect(() => {
    if (payload?.length) {
      const xCoord = payload[0].payload[XAxisName ?? ""]
      const validPayloads = payload.filter((payload_item) => {
        if (
          typeof payload_item?.value === "number" &&
          domainX.current &&
          domainY.current &&
          areCoordsOnDomain(
            xCoord,
            payload_item?.value,
            domainX.current,
            domainY.current,
            axisPadding,
            viewBox,
          )
        )
          return payload_item
      })

      setPayloadsOnDomainScale(validPayloads)
    }
  }, [XAxisName, axisPadding, domainX, domainY, payload, viewBox])

  if (payloadsOnDomainScale?.length === 0) {
    return <></>
  }

  return (
    <Stack
      gap={0.5}
      sx={{
        background: "#000000",
        padding: "15px",
        borderRadius: "2px",
        border: "1px solid #8d8d8d",
      }}
    >
      {label && (
        <Typography color="#fff">
          Time:
          <strong> {DateTime.fromMillis(label).toFormat("yyyy-MM-dd HH:mm")}</strong>
        </Typography>
      )}
      {payloadsOnDomainScale?.map((data: any) => {
        return (
          <Typography
            key={data.dataKey}
            sx={{
              color: data.color,
              fontWeight: 600,
            }}
          >
            {data.dataKey[0].toUpperCase() + data.dataKey.substring(1).replace(/_/g, " ")}
            : {valueFormatter(data.value)}
          </Typography>
        )
      })}
    </Stack>
  )
}
