import { DateTime } from "luxon"
import { useEffect, useState } from "react"

import type { IZoomArea } from "types/linePlot.types"
import type { IDateRange } from "types/date.types"
import type { IData, IDomain, IStateLineChart } from "types/plot.types"
import { TEN_MINUTES } from "../utils/constants"
import { xCoordinateFromPixels, yCoordinateFromPixels } from "../utils/plots"

interface useChartZoomProps {
  initialData: IData[]
  dateRange: IDateRange
  axisPadding?: Record<string, number>
  zoomWithRefetch: boolean
  domainX?: number[]
  domainY?: number[]
  offset?: Record<string, number>
  setDomain: (domain: IDomain) => void
}

export const useChartZoomXY = ({
  initialData,
  dateRange,
  axisPadding,
  zoomWithRefetch,
  domainX,
  domainY,
  offset,
  setDomain,
}: useChartZoomProps) => {
  const initialState = {
    data: initialData,
    left: "dataMin",
    right: "dataMax",
    refAreaLeft: 0,
    refAreaRight: 0,
    top: "dataMax+1",
    bottom: "dataMin-1",
    top2: "dataMax+20",
    bottom2: "dataMin-20",
    animation: true,
  }

  const initialZoomAreaState = {
    x1: undefined,
    y1: undefined,
    x2: undefined,
    y2: undefined,
  }

  const [state, setState] = useState<IStateLineChart>(initialState)
  const [zoomArea, setZoomArea] = useState<IZoomArea>(initialZoomAreaState)
  const [firstPoint, setFirstPoint] = useState<IZoomArea>(initialZoomAreaState)
  const [isBeingZoomed, setIsBeingZoomed] = useState<boolean>(false)
  const [zoomInAxisX, setZoomInAxisX] = useState<boolean>(false)

  const zoom = () => {
    let { x1, x2, y1, y2 } = zoomArea

    // Reverse the coordinates if is necessary
    if (y1 && y2 && y2 < y1) {
      ;[y1, y2] = [y2, y1]
    }
    if (x1 && x2 && x2 < x1) {
      ;[x1, x2] = [x2, x1]
    }

    // Catch zoom interval in X axis and if it's ranges is less than ten minutes
    if (zoomInAxisX && x2 && x1 && x2 - x1 < TEN_MINUTES) {
      setIsBeingZoomed(false)
      setZoomArea(initialZoomAreaState)
      return
    }

    // Catch zoom interval in Y axis and if the first coordinates are outside of the current domain (produced when the user click on the 'padding region') abort the zoom
    // This is needed because the 'reference area component' don't works fine in this 'padding section'
    if (domainY && y1 && y2 && (y1 < domainY[0] || y2 > domainY[1])) {
      setIsBeingZoomed(false)
      setZoomArea(initialZoomAreaState)
      return
    }

    if (zoomInAxisX && x1 !== x2) {
      setState({
        ...state,
        refAreaLeft: 0,
        refAreaRight: 0,
        left: x1 ?? 0,
        right: x2 ?? 0,
      })
    } else if (y1 !== y2) {
      setState({
        ...state,
        refAreaLeft: 0,
        refAreaRight: 0,
        top: y1 ?? 0,
        bottom: y2 ?? 0,
      })
    }
    setZoomArea(initialZoomAreaState)
    setIsBeingZoomed(false)
  }

  const onMouseDown = (e: any) => {
    if (e?.activeLabel && offset && domainX && domainY) {
      setIsBeingZoomed(true)
      setFirstPoint({ x1: e.chartX, y1: e.chartY })
    }
  }

  const onMouseMove = (e: any) => {
    if (
      !domainX ||
      !domainY ||
      domainX?.length === 0 ||
      domainY?.length === 0 ||
      !offset
    ) {
      return
    }
    if (e?.activeLabel && firstPoint.x1 && firstPoint.y1) {
      const x1 = xCoordinateFromPixels(firstPoint.x1, domainX, offset)
      const x2 = xCoordinateFromPixels(e.chartX, domainX, offset)
      const y1 = yCoordinateFromPixels(firstPoint.y1, domainY, offset, axisPadding)
      const y2 = yCoordinateFromPixels(e.chartY, domainY, offset, axisPadding)
      setIsBeingZoomed(true)
      state.refAreaLeft && setState({ ...state, refAreaRight: e.activeLabel })

      // compares the areas in pixels, so the drawn area is the largest
      const zoomedAreaX = Math.abs(e.chartX - firstPoint.x1)
      const zoomedAreaY = Math.abs(e.chartY - firstPoint.y1)
      const isZoomedInXDirection = zoomedAreaX > zoomedAreaY

      setZoomInAxisX(zoomedAreaY < zoomedAreaX)

      if (isZoomedInXDirection) {
        setZoomArea({ x1, x2 })
      } else {
        setZoomArea({ y1, y2 })
      }
    }
  }

  // ZoomOut
  const zoomOut = () => {
    setState(
      zoomWithRefetch
        ? {
            ...state,
            data: initialData,
            left: dateRange.fromDate.toMillis(),
            right: dateRange.toDate.toMillis(),
            top: "dataMax+1",
            bottom: "dataMin",
          }
        : {
            ...state,
            data: initialData,
            refAreaLeft: 0,
            refAreaRight: 0,
            left: "dataMin",
            right: "dataMax",
            top: "dataMax+1",
            bottom: "dataMin",
          },
    )
  }

  /**
   * This useEffect updates the domain in case of the user zoom in the plot
   * and this plot has zoomWithRefetch property
   */
  useEffect(() => {
    if (
      zoomWithRefetch &&
      typeof state.left === "number" &&
      typeof state.right === "number"
    ) {
      setDomain({
        fromDate: DateTime.fromMillis(state.left),
        toDate: DateTime.fromMillis(state.right + 10),
      })
    }
  }, [setDomain, zoomWithRefetch, state.left, state.right])

  return {
    zoom,
    zoomOut,
    onMouseDown,
    onMouseMove,
    isBeingZoomed,
    zoomArea,
    zoomInAxisX,
    state,
  }
}
