import type { Theme } from "@mui/material"
import { Box, Stack, Typography } from "@mui/material"
import { useTheme } from "@emotion/react"
import { DateTime } from "luxon"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { getBarPlotSeverityColor } from "../../../../helpers/utils/styles"

export const CustomBarChartTooltip = (props: any) => {
  const { active, hoveredBar, title } = props
  const [severity, setSeverity] = useState<string>()
  const [eventReported, setEventReported] = useState<string>()
  const theme = useTheme()
  const { t } = useTranslation()

  const formatHours = (deltaTimeMillisec: number[]) => {
    const fromDate = DateTime.fromMillis(deltaTimeMillisec[0])
    const toDate = DateTime.fromMillis(deltaTimeMillisec[1])
    const time = toDate.diff(fromDate, ["hours", "minutes", "seconds"]).toObject()

    if (time?.minutes && time?.hours && time?.hours >= 1) {
      return `${time.hours}hs ${time.minutes}min`
    } else {
      return `${time.minutes} min`
    }
  }

  useEffect(() => {
    if (hoveredBar) {
      const dotValues = hoveredBar[0].name.split("_")
      setSeverity(dotValues[0])
      setEventReported(dotValues[1])
    }
  }, [hoveredBar])

  if (active && hoveredBar) {
    return (
      <Stack
        gap={0.5}
        sx={{
          background: "#000000",
          padding: "15px",
          borderRadius: "2px",
          border: "1px solid #8d8d8d",
        }}
      >
        <Stack direction="row" gap={0.5} alignItems="baseline">
          {severity && (
            <Box
              sx={{
                height: "3px",
                width: "18px",
                borderRadius: "1px",
                backgroundColor: getBarPlotSeverityColor(severity, theme as Theme),
              }}
            ></Box>
          )}
          <Typography key={hoveredBar[0].namey} color="#fff">
            <strong>{t(`${title}.${eventReported?.toUpperCase()}`).toUpperCase()}</strong>{" "}
          </Typography>
        </Stack>
        <Typography key={hoveredBar[0].namey} color="#fff">
          {t("generic.FROM")}{" "}
          <strong>
            {DateTime.fromMillis(hoveredBar[0].value[0]).toFormat("yyyy-MM-dd HH:mm:ss")}
          </strong>{" "}
          {t("generic.TO").toLowerCase()}{" "}
          <strong>
            {DateTime.fromMillis(hoveredBar[0].value[1]).toFormat("yyyy-MM-dd HH:mm:ss")}
          </strong>
        </Typography>
        <Typography key={hoveredBar[0].namey} color="#fff">
          {t("generic.DURATION")}: <strong>{formatHours(hoveredBar[0].value)}</strong>
        </Typography>
      </Stack>
    )
  }

  return null
}
