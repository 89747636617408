import { useMemo } from "react"
import { Label } from "recharts"
import { useTranslation } from "react-i18next"
import { useTheme } from "@mui/material"

import type { IGaugeConfigValues } from "types/gaugePlot.types"
import { getNumericFormatter } from "helpers/formatters/plots"
import { toSentenceCase } from "helpers/utils/translations"

interface GaugeLabelProps {
  values: IGaugeConfigValues
  size: number
  decimalPlaces: number
  locale: string
}

const GaugeLabel = ({ values, size, decimalPlaces, locale }: GaugeLabelProps) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const numericFormatter = useMemo(
    () => getNumericFormatter(locale, decimalPlaces),
    [locale, decimalPlaces],
  )

  return (
    <>
      {values?.value !== undefined && (
        <Label
          value={`${values.prefix} ${numericFormatter(values.value)} ${values.suffix}`}
          position="center"
          fill={values.fill}
          style={{
            fontSize: `${size / 11}px`,
            fontWeight: 700,
            transform: "translate(-0px, -10px)",
          }}
        />
      )}
      {values?.legend !== undefined && (
        <Label
          value={toSentenceCase(
            values?.legend
              ? t(`device_information.${values.legend?.toUpperCase()}`, values.legend)
              : "",
          )}
          position="center"
          fill={values.fill}
          style={{
            fontSize: `${size / 28}px`,
            fontWeight: 500,
            transform: "translate(-0px, 25px)",
          }}
        />
      )}
      {values?.footer && (
        <Label
          value={values.footer}
          position="center"
          fill={theme.palette.text.secondary}
          style={{
            fontSize: `${size / 28}px`,
            fontWeight: 400,
            transform: "translate(-0px, 120px)",
          }}
        />
      )}
    </>
  )
}

export default GaugeLabel
