import { useMemo } from "react"
import { useTheme } from "@mui/material"

import type { RecordType, CellViewType } from "types/dashboard.types"
import type { IGaugeConfig } from "types/gaugePlot.types"

import { gaugeConfig } from "./gauge"

export default function useGaugeConfig(view: CellViewType, data: RecordType[]) {
  const { palette } = useTheme()

  const graphConfig: IGaugeConfig = useMemo(
    () => gaugeConfig({ view, data, palette }),
    [view, data, palette],
  )

  return graphConfig
}
